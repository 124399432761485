<template>
  <div id="wrap_detalle_cat">
    <!-- SLIDER -->
    <section class="image_cabecera">
      <img v-bind:src="`https://murciabarroca.es/${categoria.imagen}`" />
      <div class="row">
        <div class="col col-6 wrap-text">
          <div class="text">
            <h3>Murcia Barroca</h3>
            <h2>{{ categoria.titulo }}</h2>
          </div>
        </div>
      </div>

      <div class="row wmax slider-controls">
        <div class="col col-6">
          <a @click="$router.go(-1)"><img src="@/assets/img/flecha_left.svg" width="30" /> ATRÁS</a>
        </div>
      </div>
    </section>
    <!-- .SLIDER -->

    <!-- INTRO -->
    <section class="wmax pt-6">
      <div class="row">
        <div class="col col-xs-12 col-7">
          <h2>{{ categoria.titulo }}</h2>
          <div v-html="categoria.texto"></div>
          <p></p>
        </div>
        <div class="col col-xs-12 col-5">
          <GaleriaCategoria />
        </div>
      </div>
    </section>
    <!-- .INTRO -->
    <ListadoLugares />
    <ListadoRutas />
    <ListaCategorias />
  </div>
</template>

<script>
  import axios from "axios";
  import ListaCategorias from "@/components/ListaCategorias.vue";
  import ListadoLugares from "@/components/ListadoLugares.vue";
  import ListadoRutas from "@/components/ListadoRutas.vue";
  import GaleriaCategoria from "@/components/GaleriaCategoria.vue";

  export default {
    components: {
      ListaCategorias,
      ListadoLugares,
      ListadoRutas,
      GaleriaCategoria,
    },
    data() {
      return {
        categoria: [],
        loading: true,
        nombreCategoria: null,
      };
    },
    created() {
      this.loadCategoria();
    },
    watch: {
      $route: function () {
        this.loadCategoria();
      },
    },
    async beforeRouteUpdate() {
      await this.loadCategoria();
    },
    methods: {
      loadCategoria() {
        const categoriaId = this.$route.params.id;
        console.log(categoriaId);
        const nombreCategoria = this.$route.params.nombreCat;
        this.nombreCategoria = nombreCategoria.replace(/-/g, " ");
        return axios
          .get(`https://murciabarroca.es/api/getCategoria/${categoriaId}`)
          .then((response) => {
            this.categoria = response.data.data;
            console.log(this.categoria);
          })
          .catch((error) => {
            console.error("Error al obtener los datos:", error);
          })
          .finally(() => {
            this.loading = false;
          });
      },
    },
  };
</script>

<style>
  #wrap_detalle_cat .otras-categorias {
    margin-bottom: 0px !important;
  }

  #wrap_detalle_cat .otras-categorias .wave-bottom {
    display: none !important;
  }

  footer {
    margin-top: 0;
  }
</style>
