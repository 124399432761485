<template>
  <!-- SLIDER -->
  <section class="image_cabecera">
    <img src="@/assets/img/slider1.jpg" />
    <div class="row">
      <div class="col col-6 wrap-text">
        <div class="text">
          <h3>Murcia Barroca</h3>
          <h2>{{ ruta.titulo }}</h2>
        </div>
      </div>
    </div>

    <div class="row wmax slider-controls">
      <div class="col col-6">
        <a @click="$router.go(-1)"><img src="@/assets/img/flecha_left.svg" width="30" /> ATRÁS</a>
      </div>
    </div>
  </section>
  <!-- .SLIDER -->

  <section class="wmax intro">
    <div class="row">
      <div class="col col-12">
        <span id="title_cat" class="nombre_cat">RUTAS</span>
        <h2>{{ ruta.titulo }}</h2>
        <p style="font-weight: 600; margin-bottom: 30px">
          {{ ruta.descripcion }}
        </p>
      </div>
    </div>
  </section>

  <!-- contenido -->
  <section class="wmax contenido_lugar">
    <div class="row">
      <div class="col col-12">
        <div v-html="ruta.contenido"></div>
      </div>
    </div>
  </section>
  <!-- .contenido -->

  <section class="wmax contenido_lugar pb-8">
    <div class="row">
      <div class="col col-xs-12 col-6">
        <h3 style="margin-bottom: 25px"><strong>Esta ruta contiene los siguientes lugares:</strong></h3>

        <div class="row item_lugar_ruta" v-for="(location, index) in locations" :key="index">
          <img :src="require('@/assets/img/pin/pin' + (index + 1) + '.png')" width="35" />
          <div>
            <router-link :to="{name: 'DetalleLugar', params: {id: location.idlugar}}"
              ><h3 style="clear: both; line-height: 24px; padding-bottom: 0; padding-top: 3px; margin: 0">{{ location.name }}</h3></router-link
            >
            <p style="display: block; clear: both; font-size: 13px; margin: 0; color: #787878">
              {{ location.direccion }} <a target="_blank" style="color: darkgoldenrod" v-bind:href="location.mapa"> — Ver mapa</a>
            </p>
          </div>
        </div>
      </div>

      <div class="col col-xs-12 col-6">
        <div id="map" style="width: 100%; height: 400px">
          <GMapMap :center="{lat: 37.9857258, lng: -1.1269742}" :zoom="16" map-type-id="terrain" :options="mapOptions">
            <GMapMarker
              :key="index"
              v-for="(location, index) in locations"
              :position="{lat: location.lat, lng: location.lng}"
              :icon="require('@/assets/img/pin/pin' + (index + 1) + '.png')"
              :title="location.name"
              class="floating-marker"
              @click="
                selectedLocation = null;
                selectedLocation = location;
              "
            >
              <GMapInfoWindow v-if="selectedLocation && selectedLocation.name === location.name" @closeclick="selectedLocation = null">
                <div>{{ selectedLocation.name }}</div>
              </GMapInfoWindow>
            </GMapMarker>
            <GMapPolyline
              v-if="locations.length === lugares_ids.length"
              :path="locations"
              :options="{
                strokeColor: '#c9a167',
                strokeOpacity: 0.8,
                strokeWeight: 4,
              }"
            />
          </GMapMap>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
  import axios from "axios";
  import {ref} from "vue";
  export default {
    setup() {
      const selectedLocation = ref(null);

      // tus otros datos y métodos aquí...

      return {
        selectedLocation,
        // tus otros datos y métodos aquí...
      };
    },
    data() {
      return {
        ruta: [],
        loading: true,
        nombreCategoria: null,
        locations: [],
        lugares_ids: [],
        mapOptions: {
          styles: [
            {
              featureType: "all",
              elementType: "geometry",
              stylers: [{color: "#f5f1e4"}],
            },
            {
              featureType: "all",
              elementType: "labels.text.fill",
              stylers: [{color: "#333333"}],
            },
            {
              featureType: "all",
              elementType: "labels.text.stroke",
              stylers: [{color: "#efe4c1"}],
            },
            {
              featureType: "road",
              elementType: "geometry.fill",
              stylers: [{color: "#e5dbbb"}],
            },
          ],
        },
      };
    },
    created() {
      this.loadRuta();
    },
    methods: {
      async loadRuta() {
        const rutaId = this.$route.params.id;
        try {
          const response = await axios.get(`https://murciabarroca.es/api/getRuta/${rutaId}`);
          this.ruta = response.data.data;
          // Dividir la cadena de lugares_id en un array de ids
          this.lugares_ids = this.ruta.lugares_id.split(",");
          this.locations = [];
          for (let id of this.lugares_ids) {
            await this.getLugares(id);
          }
        } catch (error) {
          console.error("Error al obtener los datos:", error);
        } finally {
          this.loading = false;
        }
      },
      async getLugares(lugares_id) {
        console.log(lugares_id);
        if (lugares_id) {
          // Comprobar si el id existe
          try {
            const response = await axios.get(`https://murciabarroca.es/api/getLugar/${lugares_id}`);
            console.log(response.data.data);
            // Comprobar si los datos de latitud y longitud están disponibles
            if (response.data.data.latitud && response.data.data.longitud) {
              this.locations.push({
                lat: parseFloat(response.data.data.latitud),
                lng: parseFloat(response.data.data.longitud),
                name: response.data.data.titulo,
                direccion: response.data.data.direccion,
                mapa: response.data.data.maps,
                idlugar: response.data.data.id,
              });
              console.log(this.locations);
            }
          } catch (error) {
            console.error("Error al obtener los datos de los lugares:", error);
          }
        }
      },
    },
  };
</script>

<style></style>
