import { createApp } from 'vue'
import { createStore } from 'vuex'
import App from './App.vue'
import router from './router'
import AOS from 'aos'
import 'aos/dist/aos.css'
import GoogleTranslateSelect from "@google-translate-select/vue3";
import VueGoogleMaps from '@fawmi/vue-google-maps';
import VCalendar from 'v-calendar';
import 'v-calendar/style.css'; 
 
const store = createStore({
    state() {
        return {
            selectedLanguage: 'es', // idioma predeterminado
        }
    },
    mutations: {
        setSelectedLanguage(state, language) {
            state.selectedLanguage = language;
        },
    },
})
const app = createApp(App)
app.use(store)
app.use(router)
app.use(GoogleTranslateSelect);
app.use(VCalendar, {});
app.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyDxd-LgN83qPxR-AXB7yW0C3PyqPwL2L6Q',
        v: 'v4',
    }
}),
app.mixin({
    mounted() {
        this.initAOS()
    },
    methods: {
        initAOS() {
            if (AOS) {
                AOS.init({ once: false, duration: 1000, delay: 300 })
            }
        },
    },
})

app.mount('#app')
