<template>
  <!-- SLIDER -->
  <section class="image_cabecera">
    <img src="@/assets/img/slider1.jpg" />
    <div class="row">
      <div class="col col-6 wrap-text">
        <div class="text">
          <h3>Murcia Barroca</h3>
          <h2>Actividades Murcia Barroca</h2>
        </div>
      </div>
    </div>
    <div class="row wmax slider-controls">
      <div class="col col-6">
        <a @click="$router.go(-1)"><img src="@/assets/img/flecha_left.svg" width="30" /> ATRÁS</a>
      </div>
    </div>
  </section>
  <!-- .SLIDER -->
  <!-- INTRO -->
  <section class="wmax pt-6 pb-5">
    <div class="row pb-5">
      <div class="col col-xs-12 col-8">
        <h2>Actividades</h2>
        <p>
          Descubre la riqueza de Murcia Barroca a través de nuestra agenda de actividades. Un viaje fascinante que te
          llevará a través del tiempo, explorando la belleza y la historia que se esconde en
          cada rincón de nuestra querida Murcia. Desde exposiciones y conferencias hasta talleres y recorridos, cada
          actividad ha sido cuidadosamente seleccionada para iluminar la época barroca de una
          manera accesible y atractiva. Sumérgete en la cultura, el arte y la arquitectura que definen a Murcia Barroca.
          ¡Te esperamos!
        </p>
      </div>
      <div class="col col-xs-12 col-4"><v-calendar :attributes="attrs" @dayclick="onDayClick" expanded /></div>
      <!--
      
      <div class="col col-12 mt-5 pt-5">
        <h2>Navidad Barroca</h2>
        <p>
          En 2023 se conmemora el octavo centenario del primer belén de la historia, elaborado en el año 1223 por San Francisco de Asís en Greccio (Italia) y que supuso el inicio de tan hermosa
          tradición.
        </p>
        <p>
          El belén es una tradición de profundo significado y variado simbolismo a la que es posible aproximarse desde una perspectiva religiosa, estética, antropológica, artística y artesanal que, en
          mayor o menor medida, está presente en todo el mundo preservando el relato central, el cual es el eje único sobre el que se realizan distintas interpretaciones.
        </p>
        <p>Desde sus primeras representaciones el belén se afianza como una práctica eclesiástica, luego aristocrática y finalmente popular.</p>

        <div v-bind:class="{'hidden-div': !showDiv, 'visible-div': showDiv}">
          <p>
            En España el belén alcanzará su esplendor con Francisco Salzillo y Alcaraz, en el siglo XVIII, y continuará hasta la actualidad con los pesebristas catalanes y la escuela de Olot, la
            escuela granadina, murciana y gaditana (Jerez de la Frontera y El Puerto de Santa María) con numerosos talleres artesanos de carácter familiar.
          </p>
          <p>
            En el siglo XVIII se produce un hecho fundamental en la difusión del belén en España, hasta el momento limitada su presencia a los monasterios e Iglesias, con la llegada al trono de Carlos
            III, que será imitado por la aristocracia y posteriormente por la burguesía y las clases populares.
          </p>
          <p>
            Carlos III, al acceder al trono de España, en 1759, trajo consigo de Nápoles su afición por instalar el belén en su Palacio en las fechas navideñas, así como un Nacimiento napolitano que
            instaló en el Palacio del Buen Retiro y que amplió con el encargo de nuevas figuras a José Esteve Bonet y José Ginés Marín para conformar lo que será el Belén del Príncipe para su hijo
            Carlos IV, quien también lo amplió para su instalación en el Palacio Real.
          </p>
          <p>
            Este hecho fomentó la instalación de belenes en las casas al tiempo que incentivó la obra de los escultores, lo que supuso un fuerte impulso al belenismo en España. A finales del siglo
            XVIII la popularización del belén ya es un hecho. En 1786 es el momento en el que el belén abandona la exclusividad conventual y de la nobleza adinerada y alcanza a la burguesía y a las
            clases populares.
          </p>
          <p>
            Francisco Salzillo es el máximo representante de la escultura belenística del siglo XVIII en España y conformó un estilo propio: salzillesco o barroco murciano. Su belén (1783) es uno de
            los más importantes del mundo y las escenas combinan interpretaciones de textos bíblicos con pasajes populares y cotidianos, que representan el ambiente y tradiciones típicas murcianas de
            la época. Se trata de figuras que son esculturas completas de enorme valor artístico donde destacan los expresivos rostros, las manos, las vestimentas con la aplicación de la técnica del
            de las figuras hebreas.
          </p>
          <p>
            La difusión y popularización del belén supuso que la centenaria tradición de montar el belén en las casas diera origen, en el caso de Murcia, a finales del siglo XIX y principios del XX, a
            la aparición de numerosos escultores, modelistas y escenógrafos que se sintieron atraídos por la artesanía del belén; talleres artesanos de carácter familiar, ubicados en los barrios de la
            ciudad, que popularizaron la artesanía del belén y daban vida con el color, las expresiones y las ropas a las figuras de barro modeladas; así como empresas belenistas murcianas que
            abastecían al mercado nacional e internacional y fueron escuela para toda una generación de artesanos que establecieron unas sólidas bases en el proceso creador.
          </p>
          <p>
            En Murcia a lo largo de la historia de tan entrañable tradición, han abundado talleres artesanos que con su trabajo han engrandecido la tradición y este noble oficio, situando al belén
            murciano como referente nacional e internacional y han sabido transmitir su entrega a un arte popular que, en muchos casos, mantienen en activo sus descendientes, continuado con la trabajo
            iniciado por Francisco Salzillo, iniciado en el siglo XVIII.
          </p>

          <a href="#">Navegar hacia arriba &#8593;</a>
        </div>

        <button @click="toggleDiv" v-bind:textContent="buttonText" class="btn_more"></button>
    </div> -->
    </div>

    <div class="row actividades-lista">
      <div class="col col-12">
        <div v-for="(actividades, fecha) in actividadesPorDia" :key="fecha" :id="fecha" class="actividad-item">
          <h2>{{ fecha.split("-").reverse().join("/") }}</h2>
          <!-- Formato 'dd/mm/aaaa' -->
          <div class="actividad" v-for="actividad in actividades" :key="actividad.id">
            <div class="row">
              <div class="col col-12 estado_aforo" v-if="actividad.estado_aforo == 0"><span>AFORO COMPLETO</span></div>
              <div class="col col-xs-12 col-8">
                <h3>{{ actividad.titulo }}</h3>
                <p>{{ actividad.descripcion }}</p>
              </div>
              <div class="col col-xs-12 col-4 datos_actividad">
                <p><strong>Horario:</strong> {{ actividad.horario }}</p>
                <p><strong>Lugar/encuentro:</strong> {{ actividad.lugar_celebracion }}</p>

                <p v-if="actividad.estado_aforo == 0"><strong>Acceso:</strong> <span
                    style="text-decoration: underline">AFORO COMPLETO</span></p>

                <p v-else><strong>Acceso:</strong> <span v-html="actividad.acceso"></span></p>

                <p><strong>Tipo:</strong> {{ actividad.categoria }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<!--<div class="col col-4"> <v-calendar :attributes="attrs" @dayclick="onDayClick" expanded /> </div>-->
<script>
import axios from "axios";
//import { watch } from 'vue';
export default {
  data() {
    return {
      actividades: [],
      showDiv: false,
      buttonText: "Leer más",
      loading: true,
      totalActividades: 0,
      actividadesPorDia: {},
      attrs: [
        {
          key: "today",
          highlight: "red",
          dot: "red",
          order: 3,
          contentStyle: {
            color: "#fafafa", // Color del texto del día actual
            backgroundColor: "#8b2119", // Color de fondo del día actual
          },
          dates: new Date(),
        },
        {
          key: "event",
          dot: "transparent",
          dates: [new Date(2023, 10, 11), new Date(2023, 10, 10), new Date(2023, 10, 17)],
          contentStyle: {
            color: "#fafafa", // Color del texto de los días del evento
          },
          highlight: {
            color: "red",
            fillMode: "light",
          },
        },
      ],
    };
  },
  created() {
    this.loadLugares();
  },
  methods: {
    toggleDiv() {
      this.showDiv = !this.showDiv;
      this.buttonText = this.showDiv ? "Ocultar texto" : "Leer más";
    },
    loadLugares() {
      axios
        .get(`https://murciabarroca.es/api/getActividades`)
        .then((response) => {
          this.actividades = response.data.data;
          this.totalActividades = this.actividades.length;
          console.log(this.actividades);

          const fechasDeActividades = this.actividades.map((actividad) => new Date(actividad.fecha));

          // Agrupar las actividades por día
          this.actividadesPorDia = this.actividades.reduce((acc, actividad) => {
            const fecha = actividad.fecha;
            if (!acc[fecha]) {
              acc[fecha] = [];
            }
            acc[fecha].push(actividad);
            return acc;
          }, {});

          // Encuentra el objeto 'event' en attrs y reemplaza su propiedad 'dates'
          const eventAttr = this.attrs.find((attr) => attr.key === "event");
          if (eventAttr) {
            eventAttr.dates = fechasDeActividades;
          }

          // Desplazamiento suave hacia el elemento con el id 'fecha'
          this.$nextTick(() => {
            const urlParams = new URLSearchParams(this.$route.query);
            const fecha = urlParams.get("fecha");
            console.log(fecha);

            const elemento = document.getElementById(fecha);
            if (elemento) {
              console.log("El elemento existe");

              // Ajusta la posición de desplazamiento para tener en cuenta la altura de la cabecera
              const posicionAjustada = elemento.getBoundingClientRect().top + window.pageYOffset - 120;

              // Desplazamiento suave hacia la posición ajustada
              window.scrollTo({ top: posicionAjustada, behavior: "smooth" });
            } else {
              console.log("El elemento no existe");
            }
          });
        })
        .catch((error) => {
          console.error("Error al obtener los datos:", error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onDayClick(dateObj) {
      event.preventDefault();
      const date = dateObj.date;
      console.log(date);
      const fechaFormateada = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;
      const elemento = document.getElementById(fechaFormateada);
      if (elemento) {
        const posicionAjustada = elemento.getBoundingClientRect().top + window.pageYOffset - 120;
        window.scrollTo({ top: posicionAjustada, behavior: "smooth" });
        console.log("hay elemento");
      } else {
        console.log("no hay elemento");
      }
    },
  },
};
</script>
<style scoped>
.datos_actividad a {
  background-color: #8b2119;
  color: #ffffff;
  padding: 1px 8px 4px 5px;
  border-radius: 6px;
}

.btn_reservar {
  background-color: #8b2119;
  color: #ffffff;
  padding: 1px 8px 4px 5px;
  border-radius: 6px;
}

a.btn_reservar {
  background-color: #8b2119;
  color: #ffffff;
  padding: 1px 8px 4px 5px;
  border-radius: 6px;
}

.btn_more {
  background-color: #8b2119;
  color: #ffffff;
  padding: 4px 20px;
  border-radius: 20px;
  display: inline-block;
  margin-right: 20px;
  cursor: pointer;
  appearance: none;
  border: none;
  margin-top: 25px;
}

.hidden-div a,
.visible-div a {
  color: #8b2119;
}

.hidden-div {
  overflow: hidden;
  padding-top: 0;
  padding-bottom: 0;
  max-height: 0;
  border-width: 0 1px;
  -webkit-transition: max-height 0.6s linear;
  transition: max-height 0.6s linear;
}

.visible-div {
  height: auto;
  padding-top: initial;
  padding-bottom: initial;
  border-width: initial;
  -webkit-transition: max-height 0.6s linear;
  transition: max-height 0.6s linear;
}

.actividad-item h2 {
  background-color: #8b2119;
  color: #fff;
  font-size: 25px;
  line-height: 33px;
  padding: 0px 20px;
  padding-bottom: 5px;
  max-width: 170px;
  text-align: center;
  margin-top: 25px;
}

.actividad {
  margin-top: 20px;
  background-color: #f4f1e7;
  padding: 20px;
}

.actividad h3 {
  font-weight: bold;
}

.datos_actividad p {
  font-size: 14px;
  margin: 4px 0px;
}

.datos_actividad p::first-letter {
  text-transform: capitalize;
}

.estado_aforo span {
  background-color: darkgoldenrod;
  padding: 2px 10px;
  color: #fff;
  font-weight: bold;
  text-align: center;
  width: 100%;
  max-width: 220px;
  font-size: 12px;
  letter-spacing: 1px;
  border-radius: 5px;
}

.estado_aforo {
  position: absolute;
  top: -10px;
  left: -13px;
}
</style>
