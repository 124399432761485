<template>
  <div>
    <ListadoRutasFull />
  </div>
</template>

<script>
  import ListadoRutasFull from "@/components/ListadoRutasFull.vue";

  export default {
    components: {
      ListadoRutasFull,
    },
  };
</script>
