<template>
  <!-- Contenido de una categoría -->
  <section class="otras-categorias">
    <div class="wave-top">
      <img src="@/assets/img/wave-top.svg" />
    </div>
    <div class="wmax row icons-category" data-aos="fade-zoom-in" data-aos-easing="ease-in-sine" data-aos-duration="5000">
      <div class="col col-xs-6 col-3 text-center">
        <router-link to="/categorias/3/Conventos"><img src="@/assets/img/convento.svg" /></router-link>
        <router-link to="/categorias/3/Conventos">
          <h4 class="title-icon-category">Conventos</h4>
        </router-link>
      </div>

      <div class="col col-xs-6 col-3 text-center">
        <router-link to="/categorias/4/Murcia-Ciudad-Barroca"> <img src="@/assets/img/murcia-barroca.svg" /></router-link>
        <router-link to="/categorias/4/Murcia-Ciudad-Barroca">
          <h4 class="title-icon-category" style="letter-spacing: -0.85px">Murcia Ciudad Barroca</h4>
        </router-link>
      </div>

      <div class="col col-xs-6 col-3 text-center">
        <router-link to="/categorias/5/Escultura-Barroca">
          <img src="@/assets/img/escultura.svg" />
        </router-link>
        <router-link to="/categorias/5/Escultura-Barroca">
          <h4 class="title-icon-category">Escultura Barroca</h4>
        </router-link>
      </div>

      <div class="col col-xs-6 col-3 text-center">
        <router-link to="/categorias/6/Musica-Barroca">
          <img src="@/assets/img/musica.svg" />
        </router-link>
        <router-link to="/categorias/6/Musica-Barroca">
          <h4 class="title-icon-category">Música Barroca</h4>
        </router-link>
      </div>

      <div class="col col-xs-6 col-3 text-center">
        <router-link to="/categorias/1/Iglesias">
          <img src="@/assets/img/iglesias.svg" />
        </router-link>
        <router-link to="/categorias/1/Iglesias">
          <h4 class="title-icon-category">Iglesias</h4>
        </router-link>
      </div>

      <div class="col col-xs-6 col-3 text-center">
        <router-link to="/categorias/7/literatura">
          <img src="@/assets/img/literatura.svg" />
        </router-link>
        <router-link to="/categorias/7/literatura">
          <h4 class="title-icon-category">Literatura</h4>
        </router-link>
      </div>

      <div class="col col-xs-6 col-3 text-center">
        <router-link to="/categorias/8/Orfebrería">
          <img src="@/assets/img/orfebreria.svg" />
        </router-link>
        <router-link to="/categorias/8/Orfebrería">
          <h4 class="title-icon-category">Orfebrería</h4>
        </router-link>
      </div>

      <div class="col col-xs-6 col-3 text-center">
        <router-link to="/categorias/2/Palacios">
          <img src="@/assets/img/palacios.svg" />
        </router-link>
        <router-link to="/categorias/2/Palacios">
          <h4 class="title-icon-category">Palacios</h4>
        </router-link>
      </div>
    </div>
    <div class="wave-bottom">
      <img src="@/assets/img/wave-bottom.svg" />
    </div>
  </section>
</template>

<script>
  export default {
    // Lógica del componente
  };
</script>

<style scoped>
  /* Estilos específicos de una categoría */
</style>
