<template>
  <div id="app">
    <Cabecera />
    <router-view v-if="!maintenanceMode" />
    <maintenance-page v-else />
    <PieDePagina />
  </div>
</template>

<script>
  import Cabecera from "@/components/CabeceraHeader.vue";
  import PieDePagina from "@/components/PieDePagina.vue";
  import MaintenancePage from "./views/MaintenancePage.vue";

  export default {
    name: "App",
    components: {
      Cabecera,
      PieDePagina,
      MaintenancePage,
    },
    data() {
      return {
        maintenanceMode: false, // Cambie esto a "false" cuando haya terminado con las tareas de mantenimiento.
      };
    },
    beforeRouteEnter(to, from, next) {
      const headerHeight = document.getElementById("header").offsetHeight;
      window.scrollTo(0, 0 - headerHeight);
      next();
    },
  };
</script>

<style>
  @import "@/assets/css/style.css";
  /* Ruta al archivo CSS */
</style>
