<template>
  <section class="wmax list-items-wrapper" v-if="lugares.length > 0">
    <div class="row">
      <div class="col col-8 vcenter">
        <h2 class="title-two-colors">
          <span id="title_cat" class="nombre_cat nombre_cat_min">{{ formattedNombreCategoria }}
          </span>
          <span>Lugares</span>
          <span id="total_lugares" class="num_items">({{ totalLugares }})</span>
        </h2>
      </div>
      <div class="col col-4 vcenter text-right" style="padding-top: 7px">
        <!--<div class="bnt_line_orange cercaDeMi">CERCA DE MÍ</div>-->
      </div>
    </div>

    <div class="row list-item-container">
      <div class="col col-12" v-if="loading">Cargando lugares...</div>
      <div v-else v-for="lugar in lugares" :key="lugar.id" class="item item-lugar lugar">
        <router-link :to="{ name: 'DetalleLugar', params: { id: lugar.id } }">
          <div class="image-lugar">
            <img :src="`https://murciabarroca.es/${lugar.imagen}`" :alt="lugar.titulo" />
          </div>
          <!--<img src="@/assets/img/iglesias/San-Miguel.jpg" :alt="lugar.titulo"> -->
        </router-link>
        <router-link :to="{ name: 'DetalleLugar', params: { id: lugar.id } }">
          <h3>{{ lugar.titulo }}</h3>
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      lugares: [],
      loading: true,
      nombreCategoria: null,
      totalLugares: 0,
    };
  },
  created() {
    this.loadLugares();
  },
  computed: {
    formattedNombreCategoria() {
      if (this.nombreCategoria) {
        return this.decodeURL(this.nombreCategoria);
      }
      return "";
    },
  },
  methods: {
    loadLugares() {
      const categoriaId = this.$route.params.id;
      const nombreCategoria = this.$route.params.nombreCat;
      this.nombreCategoria = nombreCategoria;
      axios
        .get(`https://murciabarroca.es/api/getLugares/${categoriaId}`)
        .then((response) => {
          this.lugares = response.data.data;
          this.totalLugares = this.lugares.length;
        })
        .catch((error) => {
          console.error("Error al obtener los datos:", error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    decodeURL(url) {
      return decodeURIComponent(url.replace(/-/g, " "));
    },
  },
};
</script>

<style scoped>
/* Estilos específicos del listado de lugares */
</style>
