<template>
  <div>
    <BannerInicio />
    <TituloIntro />
    <ListaCategorias />
    <CarruselLugares />
    <ActividadesHome />
    <CarruselRutas />
    <HomeNoticias />
  </div>
</template>

<script>
  import BannerInicio from "@/components/BannerInicio.vue";
  import TituloIntro from "@/components/TituloIntro.vue";
  import ListaCategorias from "@/components/ListaCategorias.vue";
  import CarruselLugares from "@/components/CarruselLugares.vue";
  import CarruselRutas from "@/components/CarruselRutas.vue";
  import HomeNoticias from "@/components/HomeNoticias.vue";
  import ActividadesHome from "@/components/ActividadesHome.vue";
  export default {
    components: {
      BannerInicio,
      TituloIntro,
      ListaCategorias,
      CarruselLugares,
      ActividadesHome,
      CarruselRutas,
      HomeNoticias,
    },
    data() {
      return {
        categorias: [
          // Define tus categorías aquí
        ],
      };
    },
  };
</script>
