<template>
  <!-- Contenido del título e introducción -->
  <section class="wmax" style="margin-top: 5vh; margin-bottom: 5vh">
    <div class="row">
      <div class="col col-xs-12 col-12">
        <div v-html="TextoIntro.valor"></div>
      </div>
    </div>
  </section>
</template>
<script>
  import axios from "axios";
  export default {
    data() {
      return {
        TextoIntro: [],
      };
    },
    created() {
      this.loadTextoIntro();
    },

    methods: {
      loadTextoIntro() {
        axios
          .get(`https://murciabarroca.es/api/getCustomHtml/3/`)
          .then((response) => {
            this.TextoIntro = response.data.data;
            console.log(this.TextoIntro);
          })
          .catch((error) => {
            console.error("Error al obtener los datos:", error);
          })
          .finally(() => {});
      },
    },
  };
</script>
