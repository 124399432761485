<template>
  <div>
    <ListadoLugaresFull />
  </div>
</template>

<script>
  import ListadoLugaresFull from "@/components/ListadoLugaresFull.vue";

  export default {
    components: {
      ListadoLugaresFull,
    },
  };
</script>
