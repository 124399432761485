<template>
  <!-- SLIDER -->
  <section class="image_cabecera">
    <img src="@/assets/img/slider1.jpg" />
    <div class="row">
      <div class="col col-6 wrap-text">
        <div class="text">
          <h3>Murcia Barroca</h3>
          <h2>Rutas</h2>
        </div>
      </div>
    </div>

    <div class="row wmax slider-controls">
      <div class="col col-6">
        <a @click="$router.go(-1)"><img src="@/assets/img/flecha_left.svg" width="30" /> ATRÁS</a>
      </div>
    </div>
  </section>
  <!-- .SLIDER -->
  <!-- .SLIDER -->
  <section class="wmax list-items-wrapper">
    <div class="row">
      <div class="col col-8 vcenter">
        <h2 class="title-two-colors">
          <span>Rutas disponibles</span>
          <span id="total_rutas" class="num_items" style="display: none !important">({{ totalRutas }})</span>
        </h2>
      </div>
      <div class="col col-4 vcenter text-right" style="padding-top: 7px">
        <!--  <div class="bnt_line_orange cercaDeMi">CERCA DE MÍ</div>-->
      </div>
    </div>

    <div class="row list-item-container">
      <div class="col col-4">
        <img src="@/assets/img/rutas_img.jpg" style="margin-top: 17px; display: none" />
        <img src="@/assets/img/img_rutas.jpg" style="margin-top: 17px" />
      </div>
      <div class="col col-8">
        <div class="row">
          <div class="col col-12" v-if="loading">Cargando rutas...</div>
          <div v-else v-for="ruta in rutas" :key="ruta.id" class="item item-ruta ruta" style="flex: 0 0 49%">
            <router-link :to="{name: 'DetalleRuta', params: {id: ruta.id}}">
              <!-- <img src="@/assets/img/iglesias/San-Miguel.jpg" :alt="ruta.titulo" />-->
              <img :src="`https://murciabarroca.es/${ruta.imagen}`" :alt="ruta.titulo" />
            </router-link>
            <router-link :to="{name: 'DetalleRuta', params: {id: ruta.id}}">
              <h3>{{ ruta.titulo }}</h3>
            </router-link>
            <!--<div class="globo_categoria">Iglesias</div>-->
          </div>
        </div>
      </div>
    </div>

    <div class="row slider-controls">
      <div class="col col-6">
        <a @click="$router.go(-1)"><img src="@/assets/img/flecha_left.svg" width="30" /> ATRÁS</a>
      </div>
    </div>
  </section>
</template>

<script>
  import axios from "axios";

  export default {
    data() {
      return {
        rutas: [],
        loading: true,
        nombreCategoria: null,
        totalRutas: 0,
      };
    },
    created() {
      this.loadRutas();
    },
    methods: {
      loadRutas() {
        axios
          .get(`https://murciabarroca.es/api/getRutasLugar/0`)
          .then((response) => {
            this.rutas = response.data.data;
            this.totalRutas = this.rutas.length;
            console.error(this.totalRutas);
          })
          .catch((error) => {
            console.error("Error al obtener los datos:", error);
          })
          .finally(() => {
            this.loading = false;
          });
      },
    },
  };
</script>

<style scoped>
  /* Estilos específicos del listado de lugares */
</style>
