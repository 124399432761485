<template>
  <section class="wmax list-item-home" v-if="lugares.length > 0">
    <div class="row">
      <div class="col col-4">
        <h2 data-aos="fade-up">Lugares</h2>
      </div>
      <div class="col col-8 text-right" style="padding-top: 7px" data-aos="fade-left">
        <!--<div class="bnt_line_orange cercaDeMi">CERCA DE MÍ</div>-->
        <router-link to="/lugares">
          <div class="bnt_orange btn_orange_mobile">VER TODOS</div>
        </router-link>
      </div>
    </div>
    <div class="row head_line"></div>
    <div class="row">
      <div class="col col-12 wrap_carrusel">
        <swiper
          :navigation="{nextEl: '.btn-next', prevEl: '.btn-prev'}"
          :modules="modules"
          class="CarruselLugares"
          :autoplay="{
            disableOnInteraction: false,
          }"
          :breakpoints="{
            // cuando el ancho de la ventana es <= 640px
            640: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            // cuando el ancho de la ventana es > 640px
            641: {
              slidesPerView: 5,
              spaceBetween: 30,
            },
          }"
        >
          <swiper-slide v-for="lugar in lugares" :key="lugar.id" class="lugar">
            <router-link :to="{name: 'DetalleLugar', params: {id: lugar.id}}">
              <div class="image-lugar">
                <img :src="`https://murciabarroca.es/${lugar.imagen}`" :alt="lugar.titulo" />
              </div>
            </router-link>
            <router-link :to="{name: 'DetalleLugar', params: {id: lugar.id}}">
              <h3>{{ lugar.titulo }}</h3>
            </router-link>
          </swiper-slide>
          <div class="row slider-controls">
            <div class="col col-6 prev btn-prev" data-aos="fade-left" style="user-select: none"><img src="@/assets/img/flecha_left.svg" width="30" /> ANTERIOR</div>
            <div class="col col-6 text-right next btn-next" data-aos="fade-right" style="user-select: none">SIGUIENTE <img src="@/assets/img/flecha_right.svg" width="30" /></div>
          </div>
        </swiper>
      </div>
    </div>
  </section>
</template>

<script>
  import axios from "axios";
  import {Swiper, SwiperSlide} from "swiper/vue";

  import "swiper/css";
  import "swiper/css/navigation";
  import "swiper/css/pagination";
  import "swiper/css/scrollbar";

  // import required modules
  import {Navigation} from "swiper/modules";
  import {Autoplay} from "swiper/modules";
  export default {
    components: {
      Swiper,
      SwiperSlide,
    },
    setup() {
      return {
        modules: [Navigation, Autoplay],
      };
    },

    data() {
      return {
        lugares: [],
        loading: true,
        totalLugares: 0,
        //selectedCursor: `url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAABOVJREFUeJztmXtQVFUcxz/LMjKyu1BTIy8ZedUYEhFWGjWIj4LKnBQKy4kmh5J0qFF72GhN/EfT1B9OljOJBhk6hQ0kBAK+xjCKNCtNJMiRijcClhThcvvj7l0v6wK7dPcuzZzPzJ0595yz+/39fvc87vldEAgEAoFAIBAIBAKB20QC24BoL2hH27SjvKANNuFWQAI2eEF/g027FS88ALXzhwB/vQ2wadbihSDMAs7bhI8CZr2EnTAdqOE/BMHgZv+ZwHEg3HZfAQy6K6ox04GltvKvQBLwm6fEViJHeypfme445O4I8AF2AVm2+7eBr938D62ZB2y0lT8CngasnhT0AT5EjnY/MN+TYhMwF7hos2UP4KuXsA9QyNUgxOslrCLBpi0BRYBRbwPUQVintzjwPF50XsEAJCIHQ2+MyFPA3XVMoMbd6BmR514YEAoEam7R5BgA2pD3/+/xwC5wF/LK34339/mJrm7krfoOVxybaASEI+/1GUrfm2bfQkRkNMGhYZgtAa5oeJw/Lg3Q2d7GLy0/09J0TqmWgE+AFxnnzXC8ACQBnwFB/iYT2WtfIOPxJ4mI8sbJ13XOtzTzaXERBe9v5a/BQYAOYDlQ76z/WAFYBHwB+C1Oe4i3tm7nxhlBHjHYU3R1tPNS7hoO11QB/A08ABxx7Ods/4wBqgHL6pxc3nlvByazxZO2egST2cKy9McY6O/j1IkGXwyGh4ESoE/dz3EEGIA64O6lyzPYtvNjDIb/9zYrSRI5WZlU7i8FOAYkq9sdvVsB7AsOCeXIiTP4+5t0MtOzXL78JwsSY+nq7AB4BChT2hzf4F4BWP/q65o6f7G3hxVpKewp2jmqfuPabF7OXTOqrriwgPS0hfRd7NVM32Qys37Ta8rtJnWbOgBhwJ0ms4X0zFWaiQM0nvmRb+uPUyUPQzv79u6mZO/uUXUHystoqK/j7OkfNLXh0SeylLVsHnJiBxgdgAcBw8L7Upnm56epuCRJ4zW6/5tJMM3PjwWLloA87VOVenUAogFuTUjUVHgqEXfb7UrRnkpXByAEICgoREeT9CU4NEwp2gvqAPgC+Bi9drT2OEajPWFkL6gD0A7Q1dmuo0n60tH+u1K0F9QBaAVoPteoo0n6ovLNfjhSB6AGoKaqnJGRER3N0ger1cqh6krltlopqANwFmjq7e7m8NWOmjArMhqzJUC9CgMwJz7hml1nTnwCloBAIqJiNLWhtqqC3p5ugEbAfmZ2fBVeB7w7OzaOymMNGDVcECVJuuZc4axuvPrJYrVaSb1nLk2NPwE8B2xX2hw9PAWs7OnuukGSJJKSUzQzwplDYzmp9QHszbwtHKgoA/nJPwPY57hjAEaQc2qrvvnqS2PozHDi4hM0NUZvigsLyH9jMxgM/yAf9i6o252N8QvIWZRltZXlDA0NMf/eZHx8vJH5njxXhofJz9tCft5mpepZ4HPHfmNN8pPIX1rTGurrfMtLS5gRFEJkVMyUf1G6MjxM5f5Scp7KpKayHGAIedjvctZ/osmWBHwAxAIEXnc9KUvuJyrmZoJDwwgImBpZ8YGBfjrb22huauTowRouDfQrTaeRnXeaD3QVI5ANfIf3U96uXieB1bjwyczd5TYCWIycLg9j6nwY6Uf+MNIKHMRhoRMIBAKBQCAQCAQCgQP/Atfav0wqDxnuAAAAAElFTkSuQmCC"), pointer`,
      };
    },
    created() {
      this.loadLugares();
    },
    methods: {
      loadLugares() {
        axios
          .get(`https://murciabarroca.es/api/getLugares/0`)
          .then((response) => {
            this.lugares = response.data.data;
            this.totalLugares = this.lugares.length;
          })
          .catch((error) => {
            console.error("Error al obtener los datos:", error);
          })
          .finally(() => {
            this.loading = false;
          });
      },
    },
  };
</script>
<style>
  .swiper {
    width: 100%;
  }
</style>
