<template>
  <footer>
    <div class="wrap-footer">
      <div class="row">
        <div class="col col-6">
          <img
            class="logo-footer"
            src="../assets/img/ayto-murcia-white.svg"
            width="220"
          />
        </div>
        <div class="col col-6 text-right">
          <img
            class="logo-footer"
            src="../assets/img/murcia_barroca_logo_white.jpg"
            width="240"
            style="margin-top: 15px"
          />
        </div>
      </div>
      <div class="row min-row links-footer">
        <div class="col col-xs-6 col-4">
          <router-link to="/lugares">
            <img src="../assets/img/flecha_right_white.svg" width="30" />
            Lugares</router-link
          >
          <router-link to="/rutas"
            ><img src="../assets/img/flecha_right_white.svg" width="30" />
            Rutas</router-link
          >
          <router-link to="/actividades"
            ><img src="../assets/img/flecha_right_white.svg" width="30" />
            Actividades</router-link
          >
        </div>
        <div class="col col-xs-6 col-4">
          <router-link to="/contacto"
            ><img src="../assets/img/flecha_right_white.svg" width="30" />
            Contacto</router-link
          >
          <a href="#"
            ><img src="../assets/img/flecha_right_white.svg" width="30" /> Aviso
            Legal</a
          >
          <a href="#"
            ><img src="../assets/img/flecha_right_white.svg" width="30" />
            Política de privacidad</a
          >
        </div>
        <div class="col col-xs-12 col-4 text-center footer_rrss">
          <p>SÍGUENOS EN</p>
          <a href="https://www.instagram.com/murciabarroca/" target="_blank">
            <img
              class="logo-footer"
              src="../assets/img/instagram.png"
              width="65"
          /></a>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col col-12 copyright">2023 © AYUNTAMIENTO DE MURCIA</div>
    </div>
  </footer>
</template>
