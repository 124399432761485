<template>
  <!-- Contenido del listado de noticias -->
  <section class="wmax list-item-home list-noticias">
    <div class="row">
      <div class="col col-4">
        <h2>Noticias</h2>
      </div>
      <div class="col col-8 text-right" style="padding-top: 7px">
        <!--<div class="bnt_orange">VER TODAS</div>-->
      </div>
    </div>
    <div class="row wrap_noticias" translate="no">
      <swiper
        :navigation="{nextEl: '.btn-next', prevEl: '.btn-prev'}"
        :modules="modules"
        class="CarruselActividades"
        :autoplay="{
          disableOnInteraction: false,
        }"
        :breakpoints="{
          // cuando el ancho de la ventana es <= 640px
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          // cuando el ancho de la ventana es > 640px
          641: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          // cuando el ancho de la ventana es > 640px
          1350: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
        }"
      >
        <swiper-slide v-for="noticia in noticias" :key="noticia.id" class="item-noticia noticia">
          <div class="image-lugar"><img v-bind:src="`https://murciabarroca.es/${noticia.imagen}`" /></div>
          <p
            class="fecha"
            v-text="
              new Date(noticia.fecha).toLocaleDateString('es-ES', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              })
            "
          ></p>
          <h2 class="titulo-noticia">{{ noticia.titulo }}</h2>
          <p class="texto-noticia">{{ truncarTexto(noticia.texto) }}</p>
          <router-link :to="{name: 'DetalleNoticia', params: {idnoticia: noticia.id}}">
            <div class="text-right next" style="user-select: none">
              VER NOTICIA COMPLETA
              <img src="@/assets/img/flecha_right.svg" width="30" />
            </div>
          </router-link>
        </swiper-slide>
        <div class="row slider-controls">
          <div class="col col-6 prev btn-prev" data-aos="fade-left" style="user-select: none"><img src="@/assets/img/flecha_left.svg" width="30" /> ANTERIOR</div>
          <div class="col col-6 text-right next btn-next" data-aos="fade-right" style="user-select: none">SIGUIENTE <img src="@/assets/img/flecha_right.svg" width="30" /></div>
        </div>
      </swiper>
    </div>
  </section>
</template>
<script>
  import axios from "axios";

  import {Swiper, SwiperSlide} from "swiper/vue";

  import "swiper/css";
  import "swiper/css/navigation";
  import "swiper/css/pagination";
  import "swiper/css/scrollbar";
  // import required modules
  import {Navigation} from "swiper/modules";
  import {Autoplay} from "swiper/modules";

  export default {
    components: {
      Swiper,
      SwiperSlide,
    },
    setup() {
      return {
        modules: [Navigation, Autoplay],
      };
    },
    data() {
      return {
        noticias: [],
        loading: true,
      };
    },
    mounted() {
      axios
        .get("https://murciabarroca.es/api/getNoticias/")
        .then((response) => {
          this.noticias = response.data.data;
          console.log(response);
        })
        .catch((error) => {
          console.error("Error al obtener los datos:", error);
        })
        .finally(() => {
          this.loading = false; // Finalmente, se oculta el estado de carga
        });
    },
    methods: {
      truncarTexto(texto) {
        if (texto.length > 100) {
          // Puedes ajustar el número de caracteres aquí
          texto = texto.replace(/<[^>]*>/g, "");
          return texto.slice(0, 100) + "...";
        }
        //return texto.replace(/<[^>]*>/g, "");
      },
    },
  };
</script>
