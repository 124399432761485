<template>
  <!-- SLIDER -->
  <section class="image_cabecera">
    <img src="@/assets/img/slider1.jpg" />
    <div class="row">
      <div class="col col-6 wrap-text">
        <div class="text">
          <h3>Murcia Barroca</h3>
          <h2>Contacto</h2>
        </div>
      </div>
    </div>

    <div class="row wmax slider-controls">
      <div class="col col-6">
        <a @click="$router.go(-1)"><img src="@/assets/img/flecha_left.svg" width="30" /> ATRÁS</a>
      </div>
    </div>
  </section>
  <!-- .SLIDER -->
  <!-- INTRO -->
  <section class="wmax pag_contacto pb-5">
    <div class="row pb-5">
      <div class="col col-12 pb-5">
        <h2>Contacto</h2>
      </div>
      <div class="col col-xs-12 col-4 text-center item_contacto">
        <img class="img_contacto" src="@/assets/img/mapa-del-mundo.png" width="120" />
        <h3>Concejalía de Cultura e Identidad</h3>
        <p>Calle Plano de San Francisco 8, 30.004 - Murcia</p>
      </div>
      <div class="col col-xs-12 col-4 text-center item_contacto">
        <img class="img_contacto" src="@/assets/img/telefono.png" width="120" />
        <h3>¿Hablamos?</h3>
        <p>968 35 86 00</p>
      </div>
      <div class="col col-xs-12 col-4 text-center item_contacto">
        <img class="img_contacto" src="@/assets/img/correo.png" width="120" />
        <h3>Escríbenos</h3>
        <p>murciabarroca@ayto-murcia.es</p>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    components: {},
  };
</script>
