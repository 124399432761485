<template>
  <div
    class="wrap_image"
    :style="{
      backgroundImage: `url(${require('@/assets/img/slider1.jpg')})`,
      '-webkit-background-size': 'cover',
      '-moz-background-size': 'cover',
      '-o-background-size': 'cover',
      backgroundSize: 'cover',
      minHeight: '500px',
    }"
  >
    <img
      class="sombra-banner"
      src="@/assets/img/sombra_banner.svg"
      width="100%"
    />
    <div class="row text-slider">
      <div class="col col-6 vcenter wrap-text">
        <div class="text" style="color: #ffffff !important">
          <h3>Murcia Barroca</h3>
          <h2>App en construcción.</h2>
        </div>
      </div>
      <div class="col col-6 vcenter flex-right wrap-texto-girado">
        <div class="text-girado">
          <h4><span>-</span> MURCIA BARROCA</h4>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
h1 {
  font-size: 36px;
  margin-bottom: 20px;
}

p {
  font-size: 24px;
}
</style>
