<template>
  <!-- Contenido del banner de inicio -->
  <!-- SLIDER -->
  <!-- SLIDER -->
  <section class="banner_principal">
    <swiper
      :navigation="{nextEl: '.btn-next', prevEl: '.btn-prev'}"
      :modules="modules"
      effect="fade"
      class="sliderHome"
      :slidesPerView="1"
      :spaceBetween="0"
      :autoplay="{
        delay: 3500,
        disableOnInteraction: false,
      }"
    >
      <swiper-slide>
        <a href="https://murciabarroca.es/actividades">
          <div
            class="wrap_image"
            :style="{
              backgroundImage: 'url(https://murciabarroca.es/upload/slider/organo.jpg)',
              backgroundPosition: 'center center',
              backgroundSize: 'cover',
              minHeight: '500px',
            }"
          >
            <div class="numbertext">1 / 1</div>

            <div class="row text-slider">
              <div class="col col-6 vbottom wrap-text">
                <div class="text" style="display: block !important">
                  <h3>Murcia Barroca</h3>
                  <h2>Noviembre.<br />Mes de la música.</h2>
                </div>
              </div>
              <div class="col col-6 vcenter flex-right wrap-texto-girado">
                <div class="text-girado">
                  <h4><span></span> MURCIA BARROCA</h4>
                </div>
              </div>
            </div>
          </div>
        </a>
      </swiper-slide>

      <div class="row slider-controls" style="background-color: #ffffff">
        <div class="col col-6 prev btn-prev" data-aos="fade-left" data-aos-delay="300" style="user-select: none"><img src="@/assets/img/flecha_left.svg" width="30" /> ANTERIOR</div>
        <div class="col col-6 text-right next btn-next" data-aos="fade-right" data-aos-delay="300" style="user-select: none">SIGUIENTE <img src="@/assets/img/flecha_right.svg" width="30" /></div>
      </div>
    </swiper>
  </section>
</template>

<script>
  import {Swiper, SwiperSlide} from "swiper/vue";

  import "swiper/css";
  import "swiper/css/navigation";
  import "swiper/css/pagination";
  import "swiper/css/scrollbar";
  import "swiper/css/effect-fade";
  // import required modules
  import {Navigation} from "swiper/modules";
  import {Autoplay} from "swiper/modules";
  import {EffectFade} from "swiper/modules";
  export default {
    name: "BannerInicio",
    components: {
      Swiper,
      SwiperSlide,
    },
    setup() {
      return {
        modules: [Navigation, Autoplay, EffectFade],
      };
    },
  };
</script>

<style scoped>
  .swiper-wrapper {
    overflow: hidden;
  }
</style>
