<template>
  <!-- Contenido del título e introducción -->
  <section class="wmax intro" style="margin-top: 5vh">
    <div class="row">
      <div class="col col-xs-12 col-7">
        <!--<h2   data-aos="fade-zoom-in" data-aos-easing="ease-in-sine"  data-aos-duration="5000">Murcia <span >Barroca</span></h2>-->
        <img class="logo" src="@/assets/img/logo.svg" />

        <div v-html="TextoIntro.valor"></div>
      </div>

      <div class="col col-xs-12 col-4" translate="no">
        <div class="data_video_imagen text-center">
          <!--<img src="@/assets/img/banner_home.jpg" width="100%" style="width: 100%; margin: 0 auto; margin-top: 20px" />-->
          <div v-html="ContentHtml.valor"></div>
        </div>
        <div class="text-center" style="margin-top: 20px; background-color: #f4f1e7; text-align: center !important">
          <v-calendar :attributes="attrs" @dayclick="onDayClick" expanded />
        </div>
      </div>
    </div>
  </section>
</template>
<script>
  import axios from "axios";
  export default {
    data() {
      return {
        actividades: [],
        loading: true,
        totalActividades: 0,
        actividadesPorDia: {},
        ContentHtml: [],
        TextoIntro: [],
        attrs: [
          {
            key: "today",
            highlight: "red",
            dot: "red",
            order: 3,
            contentStyle: {
              color: "#fafafa", // Color del texto del día actual
              backgroundColor: "#8b2119", // Color de fondo del día actual
            },
            dates: new Date(),
          },
          {
            key: "event",
            dot: "transparent",
            dates: [new Date(2023, 10, 11), new Date(2023, 10, 10), new Date(2023, 10, 17)],
            contentStyle: {
              color: "#fafafa", // Color del texto de los días del evento
            },
            highlight: {
              color: "red",
              fillMode: "light",
            },
          },
        ],
      };
    },
    created() {
      this.loadLugares();
      this.loadVideoImagen();
      this.loadTextoIntro();
    },

    methods: {
      loadVideoImagen() {
        axios
          .get(`https://murciabarroca.es/api/getCustomHtml/1/`)
          .then((response) => {
            this.ContentHtml = response.data.data;
            console.log(this.ContentHtml);
          })
          .catch((error) => {
            console.error("Error al obtener los datos:", error);
          })
          .finally(() => {});
      },
      loadTextoIntro() {
        axios
          .get(`https://murciabarroca.es/api/getCustomHtml/2/`)
          .then((response) => {
            this.TextoIntro = response.data.data;
            console.log(this.TextoIntro);
          })
          .catch((error) => {
            console.error("Error al obtener los datos:", error);
          })
          .finally(() => {});
      },
      loadLugares() {
        axios
          .get(`https://murciabarroca.es/api/getActividades`)
          .then((response) => {
            this.actividades = response.data.data;
            this.totalActividades = this.actividades.length;
            console.log(this.actividades);

            // Asumiendo que cada actividad tiene una propiedad 'fecha' en formato 'YYYY-MM-DD'
            const fechasDeActividades = this.actividades.map((actividad) => new Date(actividad.fecha));

            // Agrupar las actividades por día
            this.actividadesPorDia = this.actividades.reduce((acc, actividad) => {
              const fecha = actividad.fecha; // Asumiendo que 'fecha' es una cadena en formato 'YYYY-MM-DD'
              if (!acc[fecha]) {
                acc[fecha] = [];
              }
              acc[fecha].push(actividad);
              return acc;
            }, {});

            // Encuentra el objeto 'event' en attrs y reemplaza su propiedad 'dates'
            const eventAttr = this.attrs.find((attr) => attr.key === "event");
            if (eventAttr) {
              eventAttr.dates = fechasDeActividades;
            }
          })
          .catch((error) => {
            console.error("Error al obtener los datos:", error);
          })
          .finally(() => {
            this.loading = false;
          });
      },
      onDayClick(dateObj) {
        // Evita que el calendario muestre un popup
        event.preventDefault();

        // Extrae el objeto Date del objeto date
        const date = dateObj.date;

        // Formatea la fecha al formato 'YYYY-MM-DD'
        const fecha = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;

        // Redirige a la página de Actividades con la fecha como parámetro en la URL
        window.location.href = `/actividades?fecha=${fecha}`;
      },
    },
  };
</script>
