<template>
  <!-- SLIDER -->
  <section class="header_min">
    <div class="mySlides fadeslider" style="display: block; overflow: hidden">
      <div
        class="wrap_image"
        :style="{
          backgroundImage: `url(${require('@/assets/img/slider1.jpg')})`,
          '-webkit-background-size': 'cover',
          '-moz-background-size': 'cover',
          '-o-background-size': 'cover',
          backgroundSize: 'cover',
          minHeight: '300px',
        }"
      >
        <div
          class="sombra-banner"
          :style="{
            backgroundImage: `url(${require('@/assets/img/sombra_banner.svg')})`,
            '-webkit-background-size': 'cover',
            '-moz-background-size': 'cover',
            '-o-background-size': 'cover',
            backgroundSize: 'cover',
            minHeight: '300px',
          }"
        ></div>
        <div class="row text-header">
          <div class="col col-6 vcenter wrap-text">
            <div class="text">
              <h3>Murcia Barroca</h3>
              <h2>Noticias</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row slider-controls">
      <div class="col col-6">
        <a @click="$router.go(-1)"
          ><img src="@/assets/img/flecha_left.svg" width="30" /> ATRÁS</a
        >
      </div>
    </div>
  </section>
  <!-- .SLIDER -->
  <!-- INTRO -->
  <section class="wmax intro">
    <div class="row">
      <div class="col col-12">
        <span id="title_cat" class="nombre_cat">NOTICIAS</span>
        <h2>{{ noticia.titulo }}</h2>
      </div>
    </div>
  </section>
  <!-- .INTRO -->
  <!-- contenido -->
  <section class="wmax contenido_lugar">
    <div class="row">
      <div class="col col-12">
        <div v-html="noticia.texto"></div>
      </div>
    </div>
  </section>
  <!-- .contenido -->
  <!-- ENLACES -->
  <section class="wmax intro">
    <div class="row">
      <div class="col col-6 wrap_enlaces_detalleLugar" style="margin-top: 3vh">
        <div class="row">
          <div class="col col-6" style="margin-bottom: 2vh">
            <!--<a href="{{noticia.fuente_url}}"
              ><img
                src="@/assets/img/flecha_right.svg"
                width="30"
                style="max-width: 30px"
              />
              Ver noticia en su fuente original.</a
            >-->
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      noticia: [],
      nombreCategoria: null,
    };
  },
  created() {
    this.loadNoticia();
  },
  methods: {
    loadNoticia() {
      const noticiaId = this.$route.params.idnoticia;
      axios
        .get(`https://murciabarroca.es/api/getNoticia/${noticiaId}`)
        .then((response) => {
          this.noticia = response.data.data;
        })
        .catch((error) => {
          console.error("Error al obtener los datos:", error);
        })
        .finally(() => {});
    },
  },
};
</script>
