import { createRouter, createWebHistory } from 'vue-router';
import PaginaInicio from '@/views/PaginaInicio.vue';
import DetalleLugar from '@/views/DetalleLugar.vue';
import DetalleRuta from '@/views/DetalleRuta.vue';
import DetalleCategoria from '@/views/DetalleCategoria.vue';
import DetalleNoticia from '@/views/DetalleNoticia.vue';
import ListadoLugaresFull from '@/views/ListadoLugaresFull.vue';
import ListadoRutasFull from '@/views/ListadoRutasFull.vue';
import MaintenancePage from '@/views/MaintenancePage.vue';
import ListadoActividades from '@/views/ListadoActividades.vue';
import PaginaContacto from '@/views/PaginaContacto.vue';
import BarrocoPedanias from '@/views/BarrocoPedanias.vue';
import MurciaBarroca from '@/views/MurciaBarroca.vue';

const routes = [
    {
        path: '/',
        name: 'Inicio',
        component: PaginaInicio
    },
    {
        path: '/lugares',
        name: 'Lugares',
        component: ListadoLugaresFull
    },
    {
        path: '/rutas',
        name: 'RutasFull',
        component: ListadoRutasFull
    },
    {
        path: '/lugares/:id',
        name: 'DetalleLugar',
        component: DetalleLugar
    }, {
        path: '/rutas/:id',
        name: 'DetalleRuta',
        component: DetalleRuta
    }, {
        path: '/categorias/:id/:nombreCat',
        name: 'DetalleCategoria',
        component: DetalleCategoria
    }, {
        path: '/noticias/:idnoticia',
        name: 'DetalleNoticia',
        component: DetalleNoticia
    }, {
        path: '/MaintenancePage',
        name: 'MaintenancePage',
        component: MaintenancePage
    }, {
        path: '/actividades',
        name: 'ListadoActividades',
        component: ListadoActividades
    }, {
        path: '/contacto',
        name: 'PaginaContacto',
        component: PaginaContacto
    }, {
        path: '/barroco-en-pedanias',
        name: 'BarrocoPedanias',
        component: BarrocoPedanias
    },
    {
        path: '/murcia-barroca',
        name: 'MurciaBarroca',
        component: MurciaBarroca
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL || '/'),
    routes,
    scrollBehavior(to) {
        if (to.hash) {
            return { el: to.hash, behavior: 'smooth' };
        } else {
            const headerHeight = document.getElementById('header').offsetHeight;
            return {
                x: 0,
                y: 0 - headerHeight
            };
        }
    }
});

router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0);
    next();
});

export default router;
