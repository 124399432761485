<template>
  <!-- SLIDER -->
  <section class="image_cabecera">
    <img v-bind:src="`https://murciabarroca.es/${lugar.imagen}`" style="width: 100%" />
    <div class="row">
      <div class="col col-6 wrap-text">
        <div class="text">
          <h3>Murcia Barroca - {{ lugar.categoria }}</h3>
          <h2>{{ lugar.titulo }}</h2>
        </div>
      </div>
    </div>
    <div class="row wmax slider-controls">
      <div class="col col-6">
        <a @click="$router.go(-1)"><img src="@/assets/img/flecha_left.svg" width="30" /> ATRÁS</a>
      </div>
    </div>
  </section>
  <!-- .SLIDER -->
  <!-- INTRO -->
  <section class="wmax intro pt-5">
    <div class="row vcenter">
      <div class="col col-xs-12 col-7">
        <span id="title_cat" class="nombre_cat">{{ lugar.categoria }}</span>
        <h2>{{ lugar.titulo }}</h2>
        <p style="font-weight: 600; margin-bottom: 0px">
          {{ lugar.descripcion }}
        </p>
      </div>
      <div class="col col-xs-12 col-5">
        <div v-if="lugar.audioSeleccionado">
          <div class="reproducir_audio" @focus="handleFocus" @focusout="handleFocusOut" tabindex="0">
            <img src="@/assets/img/reproducir-audio.svg" /><br />
            <span>Reproducir audio</span>
            <img class="icon_audio" v-on:click="toggleAudio" v-if="isPlaying" src="@/assets/img/pause.png" />
            <img class="icon_audio" v-on:click="toggleAudio" v-else src="@/assets/img/play-button.png" />
            <img class="icon_audio" v-on:click="restartAudio" src="@/assets/img/replay.png" />
            <audio :src="`https://murciabarroca.es/${lugar.audioSeleccionado}`" ref="audioPlayer"></audio>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- .INTRO -->
  <!-- contenido -->
  <section class="wmax contenido_lugar">
    <div class="row">
      <div class="col col-12">
        <div v-html="lugar.contenido"></div>
      </div>
    </div>
  </section>
  <!-- .contenido -->
  <section class="wmax pt-8 pb-5">
    <div class="row colEquals">
      <div class="col col-xs-12 col-6">
        <swiper
          :navigation="{nextEl: '.btn-next', prevEl: '.btn-prev'}"
          :modules="modules"
          class="GaleriaLugar"
          :slidesPerView="1"
          :spaceBetween="0"
          :autoplay="{
            disableOnInteraction: false,
          }"
        >
          <template v-if="Array.isArray(imagesLugar) && imagesLugar.length > 0">
            <swiper-slide v-for="images in imagesLugar" :key="images.id" class="lugar">
              <img :src="`https://murciabarroca.es/${images}`" :alt="lugar.titulo" />
            </swiper-slide>
          </template>
          <div class="row slider-controls">
            <div class="col col-6 prev btn-prev" data-aos="fade-left" style="user-select: none"><img src="../assets/img/flecha_left.svg" width="30" /> ANTERIOR</div>
            <div class="col col-6 text-right next btn-next" data-aos="fade-right" style="user-select: none">
              SIGUIENTE
              <img src="@/assets/img/flecha_right.svg" width="30" />
            </div>
          </div>
        </swiper>
      </div>
      <div class="col col-xs-12 col-6 wrap_map">
        <GMapMap
          :center="center"
          :zoom="14"
          map-type-id="terrain"
          style="width: 100%"
          :options="{
            styles: [
              {
                featureType: 'all',
                elementType: 'geometry',
                stylers: [{color: '#f5f1e4'}], // Gris claro para la geometría
              },
              {
                featureType: 'all',
                elementType: 'labels.text.fill',
                stylers: [{color: '#333333'}], // Gris oscuro para el texto
              },
              {
                featureType: 'all',
                elementType: 'labels.text.stroke',
                stylers: [{color: '#efe4c1'}], // Blanco para el trazo del texto
              },
              {
                featureType: 'road', // Esto se aplica a las carreteras
                elementType: 'geometry.fill', // Esto se aplica al relleno de las carreteras
                stylers: [{color: '#e5dbbb'}], // Aquí puedes poner el color que prefieras para las carreteras
              },
            ],
          }"
        >
          <GMapMarker :key="marker.id" v-for="marker in markers" :position="marker.position" :icon="require('@/assets/img/pin/pin.png')" :title="`hola`" />
        </GMapMap>
      </div>
    </div>
  </section>
  <section class="wmax pt-5 pb-5">
    <section class="childrens" v-if="Array.isArray(hijosLugar) && hijosLugar.length > 0">
      <div class="col col-12 pb-5">
        <h2>En el interior de {{ lugar.titulo }}...</h2>
      </div>
      <div class="col col-12">
        <swiper
          :navigation="{nextEl: '.btn-next', prevEl: '.btn-prev'}"
          :modules="modules"
          class="CarruselLugares"
          :autoplay="{
            disableOnInteraction: false,
          }"
          :breakpoints="{
            // cuando el ancho de la ventana es <= 640px
            640: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            // cuando el ancho de la ventana es > 640px
            641: {
              slidesPerView: 5,
              spaceBetween: 30,
            },
          }"
        >
          <swiper-slide v-for="hijo in hijosLugar" :key="hijo.id" class="lugar">
            <router-link :to="{name: 'DetalleLugar', params: {id: hijo.id}}">
              <div class="image-lugar">
                <img :src="`https://murciabarroca.es/${hijo.imagen}`" :alt="hijo.titulo" />
              </div>
            </router-link>
            <router-link :to="{name: 'DetalleLugar', params: {id: hijo.id}}">
              <h3>{{ hijo.titulo }}</h3>
            </router-link>
          </swiper-slide>
          <div class="row slider-controls">
            <div class="col col-6 prev btn-prev" data-aos="fade-left" style="user-select: none"><img src="@/assets/img/flecha_left.svg" width="30" /> ANTERIOR</div>
            <div class="col col-6 text-right next btn-next" data-aos="fade-right" style="user-select: none">
              SIGUIENTE
              <img src="@/assets/img/flecha_right.svg" width="30" />
            </div>
          </div>
        </swiper>
      </div>
    </section>
  </section>
  <CarruselRutas />
</template>

<script>
  import axios from "axios";
  import {ref, onMounted, onUnmounted} from "vue";
  import {Swiper, SwiperSlide} from "swiper/vue";
  import "swiper/css";
  import "swiper/css/navigation";
  import "swiper/css/pagination";
  import "swiper/css/scrollbar";
  import {Navigation} from "swiper/modules";
  import {Autoplay} from "swiper/modules";
  import CarruselRutas from "@/components/CarruselRutas.vue";

  export default {
    components: {
      Swiper,
      SwiperSlide,
      CarruselRutas,
    },
    setup() {
      const audioSource = ref(require("@/assets/audios/Las-obras-del-templo.mp3"));
      const audioPlayer = ref(null);
      let isPlaying = ref(false);

      const playAudio = () => {
        audioPlayer.value.play();
        isPlaying.value = true;
      };

      const pauseAudio = () => {
        if (audioPlayer.value) {
          audioPlayer.value.pause();
          isPlaying.value = false;
        }
      };

      const toggleAudio = () => {
        if (isPlaying.value) {
          pauseAudio();
        } else {
          playAudio();
        }
      };

      const restartAudio = () => {
        audioPlayer.value.currentTime = 0;
        if (!isPlaying.value) {
          playAudio();
        }
      };

      onMounted(() => {
        audioPlayer.value = new Audio(audioSource.value);
      });

      onUnmounted(() => {
        pauseAudio();
        audioPlayer.value = null;
      });

      return {
        modules: [Navigation, Autoplay],
        audioSource,
        audioPlayer,
        isPlaying,
        toggleAudio,
        restartAudio,
      };
    },
    data() {
      return {
        lugar: [],
        imagesLugar: [],
        hijosLugar: [],
        center: {lat: 37.9830961, lng: -1.1285042},
        markers: [
          {
            id: "dfsldjl3r",
            icon: {url: "@/assets/img/pin/pin.png"},
            position: {
              lat: 37.9830961,
              lng: -1.1285042,
            },
          },
        ],
        loading: true,
        nombreCategoria: null,
        selLanguage: localStorage.getItem("selectedLanguage") || "es",
      };
    },
    computed: {
      totalRutas() {
        return this.lugar.rutas.length;
      },
      selectedLanguage() {
        return this.$store.state.selectedLanguage;
      },
    },
    watch: {
      $route: function () {
        this.loadLugar();
      },
      selectedLanguage: {
        immediate: true,
        handler() {
          this.updateAudioSeleccionado();
        },
      },
    },
    async beforeRouteUpdate() {
      await this.loadLugar();
    },
    created() {
      this.loadLugar();
    },
    methods: {
      handleFocus() {
        // this.audioPlayer.play();
      },
      handleFocusOut() {
        this.audioPlayer.pause();
        this.isPlaying = false;
      },

      loadLugar() {
        const lugarId = this.$route.params.id;
        axios
          .get(`https://murciabarroca.es/api/getLugar/${lugarId}`)
          .then((response) => {
            this.lugar = response.data.data;
            if (this.lugar.galeria) {
              this.imagesLugar = this.lugar.galeria.split(";");
            } else {
              this.imagesLugar = [];
            }
            if (this.lugar.child) {
              this.hijosLugar = this.lugar.child;
            } else {
              this.hijosLugar = [];
            }
            if (this.lugar.latitud && this.lugar.longitud) {
              this.center = {
                lat: parseFloat(this.lugar.latitud),
                lng: parseFloat(this.lugar.longitud),
              };
              this.markers = [
                {
                  id: "dfsldjl3r",
                  icon: {url: "@/assets/img/pin/pin.png"},
                  position: {
                    lat: parseFloat(this.lugar.latitud),
                    lng: parseFloat(this.lugar.longitud),
                  },
                },
              ];
            }
            const selLanguage = localStorage.getItem("selectedLanguage");
            console.log(selLanguage);
            switch (selLanguage) {
              case "en":
                this.lugar.audioSeleccionado = this.lugar.audio_en;
                break;
              case "fr":
                this.lugar.audioSeleccionado = this.lugar.audio_fr;
                break;
              default:
                this.lugar.audioSeleccionado = this.lugar.audio;
            }
          })
          .catch((error) => {
            console.error("Error al obtener los datos:", error);
          })
          .finally(() => {
            this.loading = false;
          });
      },
      updateAudioSeleccionado() {
        switch (this.selectedLanguage) {
          case "en":
            this.lugar.audioSeleccionado = this.lugar.audio_en;
            break;
          case "fr":
            this.lugar.audioSeleccionado = this.lugar.audio_fr;
            break;
          default:
            this.lugar.audioSeleccionado = this.lugar.audio;
        }
      },
      decodeURL(url) {
        return decodeURIComponent(url.replace(/-/g, " "));
      },
    },
  };
</script>

<style>
  .vue-map-container {
    height: 100%;
  }

  .CarruselLugares h3 {
    font-weight: bold;
  }

  .swiper-slide.lugar:hover {
    opacity: 0.75;
  }
</style>
