<template>
  <section class="wmax list-item-home" v-if="rutas.length > 0">
    <div class="row">
      <div class="col col-4"></div>
      <div class="col col-8 text-right" style="padding-top: 7px" data-aos="fade-left">
        <!--<div class="bnt_line_orange cercaDeMi">CERCA DE MÍ</div>-->
        <!--< <router-link to="/rutas">
          <div class="bnt_orange">VER TODAS</div>
        </router-link>-->
      </div>
    </div>
    <div class="row head_line"></div>
    <div class="row">
      <div class="col col-xs-12 col-6">
        <h2 data-aos="fade-up">Rutas</h2>
        <div class="row" style="padding-top: 35px">
          <div class="col col-md-6"><img src="@/assets/img/rutas_img.jpg" /></div>
          <div class="col col-md-6">
            <h3 style="font-size: 24px">Rutas por la Murcia Barroca</h3>
            <p>Visitas guiadas y rutas temáticas para conocer todos los detalles de nuestra Murcia barroca.</p>
            <router-link :to="{name: 'RutasFull'}"
              ><div class="btn_reservar" style="max-width: fit-content; cursor: pointer; font-weight: bold; text-align: center; padding: 3px 15px">Ver rutas</div></router-link
            >
          </div>
        </div>
      </div>
      <div class="col col-xs-12 col-6">
        <h2 data-aos="fade-up" style="padding-bottom: 35px">Barroco en Pedanías</h2>
        <router-link :to="{name: 'BarrocoPedanias'}"><img src="@/assets/img/barroco_en_pedanias.jpg" /></router-link>
      </div>
    </div>
    <div class="row" style="display: none">
      <div class="col col-12 wrap_carrusel">
        <swiper
          :navigation="{nextEl: '.btn-next', prevEl: '.btn-prev'}"
          :modules="modules"
          class="CarruselRutas"
          :autoplay="{
            delay: 2500,
            disableOnInteraction: false,
          }"
          :breakpoints="{
            // cuando el ancho de la ventana es <= 640px
            640: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            // cuando el ancho de la ventana es > 640px
            641: {
              slidesPerView: 5,
              spaceBetween: 30,
            },
          }"
        >
          <swiper-slide v-for="ruta in rutas" :key="ruta.id" class="ruta">
            <router-link :to="{name: 'DetalleRuta', params: {id: ruta.id}}">
              <div class="image-lugar">
                <img :src="`https://murciabarroca.es/${ruta.imagen}`" :alt="ruta.titulo" />
              </div>
            </router-link>
            <router-link :to="{name: 'DetalleRuta', params: {id: ruta.id}}">
              <h3>{{ ruta.titulo }}</h3>
            </router-link>
            <div class="globo_categoria">{{ ruta.tipo_ruta }}</div>
          </swiper-slide>
          <div class="row slider-controls">
            <div class="col col-6 prev btn-prev" data-aos="fade-left" data-aos-delay="300" style="user-select: none"><img src="@/assets/img/flecha_left.svg" width="30" /> ANTERIOR</div>
            <div class="col col-6 text-right next btn-next" data-aos="fade-right" data-aos-delay="300" style="user-select: none">SIGUIENTE <img src="@/assets/img/flecha_right.svg" width="30" /></div>
          </div>
        </swiper>
      </div>
    </div>
  </section>
</template>

<script>
  import axios from "axios";
  import {Swiper, SwiperSlide} from "swiper/vue";
  import "swiper/css";
  import "swiper/css/navigation";
  import "swiper/css/pagination";
  import "swiper/css/scrollbar";
  // import required modules
  import {Navigation} from "swiper/modules";
  import {Autoplay} from "swiper/modules";
  export default {
    components: {
      Swiper,
      SwiperSlide,
    },
    setup() {
      return {
        modules: [Navigation, Autoplay],
      };
    },
    data() {
      return {
        rutas: [],
        loading: true,
        totalRutas: 0,
        tipoRuta: 0,
        isLugar: 0,
      };
    },
    created() {
      this.loadRutas();
    },
    methods: {
      loadRutas() {
        let isLugar = 0;
        if (this.$route.params.id) {
          isLugar = this.$route.params.id;
        }
        axios
          .get(`https://murciabarroca.es/api/getRutasLugar/${isLugar}`)
          .then((response) => {
            this.rutas = response.data.data;
            this.totalRutas = this.rutas.length;
            console.log(this.rutas);
          })
          .catch((error) => {
            console.error("Error al obtener los datos:", error);
          })
          .finally(() => {
            this.loading = false;
          });
      },
    },
  };
</script>
<style>
  .swiper {
    width: 100%;
  }

  .globo_categoria {
    top: 10px;
    right: 10px;
  }
</style>
