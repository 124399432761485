<template>
  <!-- SLIDER -->
  <section class="image_cabecera">
    <img src="@/assets/img/slider1.jpg" />
    <div class="row">
      <div class="col col-6 wrap-text">
        <div class="text">
          <h3>Murcia Barroca</h3>
          <h2>Lugares</h2>
        </div>
      </div>
    </div>

    <div class="row wmax slider-controls">
      <div class="col col-6">
        <a @click="$router.go(-1)"><img src="@/assets/img/flecha_left.svg" width="30" /> ATRÁS</a>
      </div>
    </div>
  </section>
  <!-- .SLIDER -->

  <!-- .SLIDER -->
  <section class="wmax list-items-wrapper">
    <div class="row">
      <div class="col col-8 vcenter">
        <h2 class="title-two-colors">
          <span>Lugares</span>
          <span id="total_lugares" class="num_items">({{ totalLugares }})</span>
        </h2>
      </div>
      <div class="col col-4 vcenter text-right" style="padding-top: 7px">
        <!--<div class="bnt_line_orange cercaDeMi">CERCA DE MÍ</div>-->
      </div>
    </div>

    <div class="row list-item-container">
      <div class="col col-12" v-if="loading">Cargando lugares...</div>
      <div v-else v-for="lugar in lugares" :key="lugar.id" class="item item-lugar lugar">
        <router-link :to="{ name: 'DetalleLugar', params: { id: lugar.id } }">
          <!-- <img :src="`@/assets/img/iglesias/${lugar.imagen}`" :alt="lugar.titulo">-->
          <div class="image-lugar">
            <img :src="`https://murciabarroca.es/${lugar.imagen}`" :alt="lugar.titulo" />
          </div>
        </router-link>
        <router-link :to="{ name: 'DetalleLugar', params: { id: lugar.id } }">
          <h3>{{ lugar.titulo }}</h3>
        </router-link>
        <div class="globo_categoria" style="top: 25px; right: 24px">
          {{ lugar.categoria }}
        </div>
      </div>
    </div>

    <div class="row slider-controls">
      <div class="col col-6">
        <a @click="$router.go(-1)"><img src="@/assets/img/flecha_left.svg" width="30" /> ATRÁS</a>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      lugares: [],
      loading: true,
      nombreCategoria: null,
      totalLugares: 0,
    };
  },
  created() {
    this.loadLugares();
  },
  methods: {
    loadLugares() {
      axios
        .get(`https://murciabarroca.es/api/getLugares/0`)
        .then((response) => {
          this.lugares = response.data.data;
          this.totalLugares = this.lugares.length;
        })
        .catch((error) => {
          console.error("Error al obtener los datos:", error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script> 