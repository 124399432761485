<template>
  <section class="wmax list-items-wrapper" v-if="totalRutas > 0">
    <div class="row">
      <div class="col col-8">
        <span id="title_cat" class="nombre_cat nombre_cat_min">{{ formattedNombreCategoria }} </span>
        <h2 class="title-two-colors">
          <span>Rutas</span>
          <span id="total_rutas" class="num_items">({{ totalRutas }})</span>
        </h2>
      </div>
      <div class="col col-4 text-right" style="padding-top: 7px">
        <!--<div class="bnt_line_orange cercaDeMi">CERCA DE MÍ</div>-->
      </div>
    </div>

    <div class="row list-item-container">
      <div class="col col-12" v-if="loading">Cargando rutas...</div>
      <div v-else v-for="ruta in rutas" :key="ruta.id" class="item item-ruta ruta">
        <router-link :to="{name: 'DetalleRuta', params: {id: ruta.id}}">
          <!-- <img :src="`@/assets/img/iglesias/${lugar.imagen}`" :alt="lugar.titulo">-->
          <div class="image-lugar">
            <img :src="`https://murciabarroca.es/${ruta.imagen}`" :alt="ruta.titulo" />
          </div>
        </router-link>
        <router-link :to="{name: 'DetalleRuta', params: {id: ruta.id}}">
          <h3>{{ ruta.titulo }}</h3>
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
  import axios from "axios";

  export default {
    data() {
      return {
        rutas: [],
        loading: true,
        nombreCategoria: null,
        totalRutas: 0,
      };
    },
    created() {
      this.loadRutas();
    },
    computed: {
      formattedNombreCategoria() {
        if (this.nombreCategoria) {
          return this.decodeURL(this.nombreCategoria);
        }
        return "";
      },
    },
    methods: {
      loadRutas() {
        const categoriaId = this.$route.params.id;
        const nombreCategoria = this.$route.params.nombreCat;
        this.nombreCategoria = nombreCategoria;
        axios
          .get(`https://murciabarroca.es/api/getRutas/${categoriaId}`)
          .then((response) => {
            this.rutas = response.data.data;
            this.totalRutas = this.rutas.length;
          })
          .catch((error) => {
            console.error("Error al obtener los datos:", error);
          })
          .finally(() => {
            this.loading = false;
          });
      },
      decodeURL(url) {
        return decodeURIComponent(url.replace(/-/g, " "));
      },
    },
  };
</script>
