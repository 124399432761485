<template>
  <div class="row wrap_accesibilidad">
    <div class="col col-12 vcenter">
      <nav>
        <ul>
          <li>Accesibilidad:</li>
          <li style="vertical-align: -4px">
            <a href="#" @click="tamañoTexto = 80"
              style="font-style: italic; font-size: 16px; font-weight: 600; margin-right: 8px">A</a>
            <a href="#" @click="tamañoTexto = 100"
              style="font-style: italic; font-weight: 600; font-size: 22px; margin-right: 8px">A</a>
            <a href="#" @click="tamañoTexto = 120" style="font-style: italic; font-weight: 600; font-size: 25px">A</a>
          </li>
          <li class="icons_flags">
            <a href="#" @click="alternarBlancoNegro"><img src="@/assets/img/btn-byn.jpg" width="20" /></a>
          </li>
        </ul>
      </nav>
    </div>
  </div>

  <header id="header" :class="{scrolled: scrolled}">
    <div class="row">
      <div class="col col-4 vcenter">
        <router-link to="/"><img class="logo" src="@/assets/img/logo.svg" width="300" /></router-link>
      </div>
      <div class="col col-8 vcenter hidden-xs">
        <nav>
          <ul>
            <li class="active">
              <router-link to="/"> Inicio</router-link>
            </li>
            <li>
              <router-link to="/lugares">Lugares</router-link>
            </li>
            <li>
              <router-link to="/rutas">Rutas</router-link>
            </li>
            <li>
              <router-link to="/actividades">Actividades</router-link>
            </li>
          </ul>
        </nav>
        <google-translate-select @select="googleTranslateSelectedHandler" :defaultLanguageCode="selectedLanguage"
          defaultPageLanguageCode="es" :languages="[
            {
              code: 'es',
              name: 'Español',
              cname: '西班牙语',
              ename: 'Spanish',
            },
            {code: 'en', name: 'English', cname: '英语', ename: 'English'},
            {code: 'fr', name: 'Français', cname: '法语', ename: 'French'},
          ]" />
      </div>
      <div id="burger-menu" @click="toggleMenu" :class="{close: showMenu}">
        <p>MÁS</p>
        <span></span>
      </div>
    </div>
    <div id="menu" :class="{overlay: showMenu}">
      <ul class="wrap_menu">
        <li class="imago_menu">
          <img src="../assets/img/imago-murcia-barroca.svg" width="80" />
        </li>
        <li>
          <router-link to="/" @click="hideMenu"> <span>—</span> Inicio</router-link>
        </li>
        <li>
          <router-link to="/lugares" @click="hideMenu"> <span>—</span> Lugares</router-link>
        </li>
        <li>
          <router-link to="/rutas" @click="hideMenu"><span>—</span> Rutas</router-link>
        </li>
        <li>
          <router-link to="/actividades" @click="hideMenu"> <span>—</span> Actividades</router-link>
        </li>
        <li>
          <router-link to="/contacto" @click="hideMenu"><span>—</span> Contacto</router-link>
        </li>
      </ul>
      <google-translate-select @select="googleTranslateSelectedHandler" :defaultLanguageCode="selectedLanguage"
        defaultPageLanguageCode="es" :languages="[
            {
              code: 'es',
              name: 'Español',
              cname: '西班牙语',
              ename: 'Spanish',
            },
            {code: 'en', name: 'English', cname: '英语', ename: 'English'},
            {code: 'fr', name: 'Français', cname: '法语', ename: 'French'},
          ]" />
    </div>
  </header>
</template>

<script>
/* eslint-disable */
export default {
  data() {
    return {
      scrolled: false,
      blancoNegro: false,
      tamañoTexto: 100,
      showMenu: false,
      selectedLanguage: "es",
    };
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  watch: {
    tamañoTexto(newValue) {
      document.body.style.fontSize = newValue + "%";
    },
  },
  mounted() {
    this.actualizarColor();
    document.body.style.fontSize = this.tamañoTexto + "%";
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    googleTranslateSelectedHandler(language) {
      localStorage.setItem("selectedLanguage", language.code);
      this.$root.selectedLanguage = language.code;
      console.log("Idioma seleccionado: " + this.$root.selectedLanguage);
      this.$store.commit("setSelectedLanguage", language.code);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    hideMenu() {
      this.showMenu = false;
    },
    alternarBlancoNegro() {
      this.blancoNegro = !this.blancoNegro;
      this.actualizarColor();
    },
    actualizarColor() {
      if (this.blancoNegro) {
        document.body.style.filter = "grayscale(100%)";
      } else {
        document.body.style.filter = "none";
      }
    },
    handleScroll() {
      this.scrolled = window.scrollY > 30;
    },
  },
};
</script>
