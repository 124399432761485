<template>
  <!-- SLIDER -->
  <section class="image_cabecera">
    <img src="@/assets/img/slider1.jpg" />
    <div class="row">
      <div class="col col-6 wrap-text">
        <div class="text">
          <h3>Murcia Barroca</h3>
          <h2>Barroco en Pedanías</h2>
        </div>
      </div>
    </div>

    <div class="row wmax slider-controls">
      <div class="col col-6">
        <a @click="$router.go(-1)"><img src="@/assets/img/flecha_left.svg" width="30" /> ATRÁS</a>
      </div>
    </div>
  </section>
  <!-- .SLIDER -->
  <!-- INTRO -->
  <section class="wmax pt-5 pb-5">
    <div class="row pb-5">
      <div class="col col-12 pb-5"></div>
      <div class="col col-xs-12 col-4 text-center">
        <img src="@/assets/img/aljucer.jpg" width="100%" />
        <p style="color: #484848; font-style: italic; font-size: 13px; text-align: center">Imagen de la fachada de la iglesia de Aljucer.</p>
      </div>
      <div class="col col-xs-12 col-1"></div>
      <div class="col col-xs-12 col-7">
        <h2>Barroco en pedanías</h2>
        <p>
          Durante el siglo XVIII se produjo el aumento de rotulaciones agrícolas lo que llevó consigo un incremento considerable de la superficies cultivables. Se generan nuevos núcleos de población,
          que demandan lugares de culto. Al igual que sucede en el núcleo histórico de la ciudad de Murcia durante este siglo se reedifican numerosos templos y la bonanza económica se ve reflejada
          tanto en las construcciones, como en las obras de escultura, pintura o retablística con las que se dotan.
        </p>
        <p>
          Encontramos también edificios civiles y viviendas palaciegas, que nos hablan del esplendor de la época barroca. Te invitamos a recorrer algunas de las pedanías que aún hoy, conservan la
          huella que el esplendor de esta centuria dejo en ellas.
        </p>
        <p><strong>No te olvides visitar próximamente la web, para descubrir las nuevas rutas que estamos preparando para ti.</strong></p>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    components: {},
  };
</script>
