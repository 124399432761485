<template>
  <swiper :navigation="{ nextEl: '.btn-next', prevEl: '.btn-prev' }" :modules="modules" class="CarruselLugares" :autoplay="{
    delay: 2500,
    disableOnInteraction: false,
  }" :breakpoints="{
  // cuando el ancho de la ventana es <= 640px
  640: {
    slidesPerView: 1,
    spaceBetween: 20,
  },
  // cuando el ancho de la ventana es > 640px
  641: {
    slidesPerView: 1,
    spaceBetween: 30,
  },
}">
    <swiper-slide v-for="imagen in this.galerias" :key="imagen" class="galeriaCategoria">
      <img :src="`https://murciabarroca.es/${imagen}`" alt="Lugar" />
    </swiper-slide>
    <div class="row slider-controls">
      <div class="col col-6 prev btn-prev" data-aos="fade-left" data-aos-delay="300" style="user-select: none">
        <img src="@/assets/img/flecha_left.svg" width="30" /> ANTERIOR
      </div>
      <div class="col col-6 text-right next btn-next" data-aos="fade-right" data-aos-delay="300"
        style="user-select: none">
        SIGUIENTE <img src="@/assets/img/flecha_right.svg" width="30" />
      </div>
    </div>
  </swiper>
</template>

<script>
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/vue";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

// import required modules
import { Navigation } from "swiper/modules";
import { Autoplay } from "swiper/modules";
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Navigation, Autoplay],
    };
  },

  data() {
    return {
      galerias: [],
      loading: true,
      totalGalerias: 0,
    };
  },
  created() {
    this.loadGalerias();
  },
  methods: {
    loadGalerias() {
      const categoriaId = this.$route.params.id;
      const nombreCategoria = this.$route.params.nombreCat;
      this.nombreCategoria = nombreCategoria;
      axios
        .get(`https://murciabarroca.es/api/getGaleriaCategoria/${categoriaId}`)
        .then((response) => {
          let galerias = response.data.data.map((item) =>
            item.galeria.split(";")
          );
          // Aplanar el array de arrays en un solo array
          this.galerias = [].concat(...galerias);
        })
        .catch((error) => {
          console.error("Error al obtener los datos:", error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style>
.swiper {
  width: 100%;
}
</style>
