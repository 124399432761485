<template>
  <section class="wmax list-item-hom pt-5">
    <div class="row">
      <div class="col col-7">
        <h2 data-aos="fade-up" style="padding-bottom: 10px">Actividades Murcia Barroca</h2>
      </div>
      <div class="col col-5 text-right">
        <router-link to="/actividades">
          <div class="bnt_orange">VER TODAS</div>
        </router-link>
      </div>
    </div>
    <div class="row head_line"></div>
    <div class="row pt-5">
      <div class="col col-xs-12 col-8">
        <swiper
          :navigation="{nextEl: '.btn-next', prevEl: '.btn-prev'}"
          :modules="modules"
          class="CarruselActividades"
          :autoplay="{
            disableOnInteraction: false,
          }"
          :breakpoints="{
            // cuando el ancho de la ventana es <= 640px
            640: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            // cuando el ancho de la ventana es > 640px
            641: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            // cuando el ancho de la ventana es > 640px
            1350: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
          }"
        >
          <swiper-slide v-for="actividad in actividades" :key="actividad.id" class="actividad">
            <p class="fecha_actividad">{{ actividad.fechaAct }}</p>
            <p class="actividad_tipo">{{ actividad.categoria }}</p>
            <h3>{{ actividad.titulo }}</h3>
            <p class="descripcion">{{ actividad.descripcion }}</p>

            <p class="acceso" v-if="actividad.estado_aforo == 0"><strong>ACCESO:</strong> <br /><span style="text-decoration: underline">AFORO COMPLETO</span></p>
            <p class="acceso" v-else>
              <strong>ACCESO:</strong> <br />
              <span v-html="actividad.acceso"></span>
            </p>
          </swiper-slide>
          <div class="row slider-controls">
            <div class="col col-6 prev btn-prev" data-aos="fade-left" style="user-select: none"><img src="@/assets/img/flecha_left.svg" width="30" /> ANTERIOR</div>
            <div class="col col-6 text-right next btn-next" data-aos="fade-right" style="user-select: none">SIGUIENTE <img src="@/assets/img/flecha_right.svg" width="30" /></div>
          </div>
        </swiper>
      </div>
      <div class="col col-xs-12 col-4" translate="no">
        <v-calendar :attributes="attrs" @dayclick="onDayClick" expanded />
      </div>
    </div>
  </section>
</template>

<script>
  import axios from "axios";
  import {Swiper, SwiperSlide} from "swiper/vue";

  import "swiper/css";
  import "swiper/css/navigation";
  import "swiper/css/pagination";
  import "swiper/css/scrollbar";
  // import required modules
  import {Navigation} from "swiper/modules";
  import {Autoplay} from "swiper/modules";
  export default {
    components: {
      Swiper,
      SwiperSlide,
    },
    setup() {
      return {
        modules: [Navigation, Autoplay],
      };
    },
    data() {
      return {
        actividades: [],
        loading: true,
        totalActividades: 0,
        actividadesPorDia: {},
        attrs: [
          {
            key: "today",
            highlight: "red",
            dot: "red",
            order: 3,
            contentStyle: {
              color: "#fafafa", // Color del texto del día actual
              backgroundColor: "#8b2119", // Color de fondo del día actual
            },
            dates: new Date(),
          },
          {
            key: "event",
            dot: "transparent",
            dates: [new Date(2023, 10, 11), new Date(2023, 10, 10), new Date(2023, 10, 17)],
            contentStyle: {
              color: "#fafafa", // Color del texto de los días del evento
            },
            highlight: {
              color: "red",
              fillMode: "light",
            },
          },
        ],
      };
    },
    created() {
      this.loadLugares();
    },

    methods: {
      loadLugares() {
        axios
          .get(`https://murciabarroca.es/api/getActividades`)
          .then((response) => {
            this.actividades = response.data.data;
            this.totalActividades = this.actividades.length;
            this.actividades = this.actividades.map((actividad) => {
              // Crea una nueva propiedad 'fechaAct' con el formato dd/mm/aaaa
              actividad.fechaAct = actividad.fecha.split("-").reverse().join("/");
              return actividad;
            });
            // Asumiendo que cada actividad tiene una propiedad 'fecha' en formato 'YYYY-MM-DD'
            const fechasDeActividades = this.actividades.map((actividad) => new Date(actividad.fecha));
            // Encuentra el objeto 'event' en attrs y reemplaza su propiedad 'dates'
            const eventAttr = this.attrs.find((attr) => attr.key === "event");
            if (eventAttr) {
              eventAttr.dates = fechasDeActividades;
            }
          })
          .catch((error) => {
            console.error("Error al obtener los datos:", error);
          })
          .finally(() => {
            this.loading = false;
          });
      },
      onDayClick(dateObj) {
        // Evita que el calendario muestre un popup
        event.preventDefault();

        // Extrae el objeto Date del objeto date
        const date = dateObj.date;

        // Formatea la fecha al formato 'YYYY-MM-DD'
        const fecha = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;

        // Redirige a la página de Actividades con la fecha como parámetro en la URL
        window.location.href = `/actividades?fecha=${fecha}`;
      },
    },
  };
</script>

<style scope>
  .actividad {
    background-color: #f4f1e7;
    padding: 0 15px;
    position: relative;
  }

  .actividad h3 {
    font-size: 20px;
    line-height: 25px;
    font-weight: bold;
    text-align: left;
  }

  .actividad_tipo {
    position: absolute;
    top: 2px;
    right: 10px;
    font-weight: bold;
    font-size: 12px;
    text-transform: uppercase;
    color: #8b2119;
  }

  .fecha_actividad {
    background-color: #8b2119;
    color: #fff;
    font-size: 13px;
    padding: 0px 5px;
    max-width: 85px;
    text-align: center;
    font-weight: bold;
  }

  .actividad .descripcion {
    font-size: 13px;
    margin-bottom: 65px;
  }

  .swiper-slide.actividad {
    display: flex;
    flex-direction: column;
    height: auto;
  }

  .CarruselActividades {
    margin-bottom: 6vh;
  }

  .acceso {
    position: absolute;
    bottom: 2px;
    left: 10px;

    font-size: 12px;
    text-transform: uppercase;
  }

  .acceso strong {
    color: #8b2119;
    font-weight: bold;
  }
</style>
